import { Routes } from '@angular/router';
import { PreviewStoreLotteryPage } from '@twogate/slashkit';

const previewRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'lottery/:lotterySlug',
        component: PreviewStoreLotteryPage,
      },
    ],
  },
];

export default previewRoutes;
